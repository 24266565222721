import { useTranslation } from "react-i18next";
import { Grid, Box, Switch } from "@material-ui/core";
import CustomTextField from "../../../../forms/CustomTextField";
import React from "react";

interface ContactPersonSectionProps {
  skipGuardians: boolean;
  // eslint-disable-next-line no-unused-vars
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const ContactPersonSection: React.FC<ContactPersonSectionProps> = ({
  skipGuardians,
  setFieldValue,
}) => {
  const { t } = useTranslation(["organization"]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue("skip_guardians", event.target.checked);
  };

  return (
    <>
      <Box style={{ paddingTop: "1rem" }}>
        <h3>{t("contactPerson", { ns: "organization" })}</h3>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            fullWidth
            required
            id="contact-person-firstname"
            name="contact_person_firstname"
            label={t("forms.contactPersonFirstname", { ns: "organization" })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            fullWidth
            required
            id="contact-person-lastname"
            name="contact_person_lastname"
            label={t("forms.contactPersonLastname", { ns: "organization" })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            fullWidth
            required
            id="contact-person-email"
            name="contact_person_email"
            label={t("forms.contactPersonEmail", { ns: "organization" })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            fullWidth
            required
            id="contact-person-phone"
            name="contact_person_phone"
            label={t("forms.contactPersonPhone", { ns: "organization" })}
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: "0.25rem", fontWeight: "bold" }}>
          {t("guardianSubheading", { ns: "organization" })}
        </Grid>
        <Grid item xs={12}>
          <Box className="confirm-condition-box">
            {t("skipGuardian", { ns: "organization" })}
            <Switch
              color="primary"
              checked={skipGuardians}
              onChange={handleChange}
              name="SkipGuardiansCheck"
              inputProps={{ "aria-label": "Skip Guardians checkbox" }}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ContactPersonSection;
