/* eslint-disable camelcase */
import moment from "moment";
import { ELearningParticipationStatus } from "./enums/participationStatus.enum";
import { TrainingType } from "./enums/trainingType.enum";
import {
  CrudEventDataDto,
  EventDataDto,
  EventFormData,
  EventListData,
  mapAddEventToDto,
  mapDateAndTime,
  mapEditEventDtoToEventForm,
  mapEventFormDataToEventListData,
  MyEventData,
  MyEventListData,
} from "./eventData";
import { ReducedBookedEventsByUserData } from "./mailHistory";

export interface ELearningListData extends EventListData {
  number_of_units?: number;
  duration_per_unit_in_minutes?: number;
  evaluation_link_blended_learning?: string;
}

export interface ELearningDto extends ELearningData, EventDataDto {}

export interface ELearningData
  extends CrudEventDataDto,
    ELearningParticipantAuthenticationDto {
  number_of_units: number;
  duration_per_unit_in_minutes: number;
  max_time_period_to_finish_elearning_module_in_weeks?: number;
  pinboard_is_activated: boolean;
  publish_elearning_after_event: boolean;
  evaluation_link_blended_learning?: string;
  elearning_link: string;
}

export interface ELearningParticipantAuthenticationDto {
  participant_authentication: boolean;
}

export interface ELearningFormData extends EventFormData {
  numberOfUnits?: number;
  durationPerUnitInMinutes?: number;
  maxTimePeriodToFinishElearningModuleInWeeks?: number;
  pinboardIsActivated?: boolean;
  publishELearningAfterEvent?: boolean;
  evaluationLinkBlendedLearning?: string;
  participantAuthentication?: boolean;
}

export interface MyELearningListData extends MyEventListData {
  user_has_clicked_evaluation_link_blended_learning: boolean;
  reactivated: boolean;
  elearning_status: ELearningParticipationStatus;
  is_within_elearning_period: boolean;
  elearning_started_count: number;
  restrict_access: boolean;
  elearning_registration_id: string | null;
}

export interface MyELearningData extends MyEventData {
  evaluation_link_blended_learning?: string;
  pinboard_is_activated: boolean;
  elearning_registration_id: string | null;
}

export type EdgeDates = {
  begin: Date;
  end?: Date | null;
};

export const getELearningDates: (
  // eslint-disable-next-line no-unused-vars
  booking: MyEventListData | ReducedBookedEventsByUserData
) => EdgeDates = (booking) => {
  const begin = new Date(
    booking?.event?.publish_elearning_after_event
      ? booking?.event?.end
      : booking?.created_on
  );
  let end = null;
  if (booking?.event?.max_time_period_to_finish_elearning_module_in_weeks) {
    end = new Date(begin);
    end.setDate(
      begin.getDate() +
        booking?.event?.max_time_period_to_finish_elearning_module_in_weeks * 7
    );
  }
  return { begin, end };
};

export function mapToELearningFormDatatoListData(
  data: ELearningFormData
): ELearningListData {
  const previewEventData: ELearningListData = {
    ...mapEventFormDataToEventListData(data),
    number_of_units: data.numberOfUnits,
    duration_per_unit_in_minutes: data.durationPerUnitInMinutes,
  };
  return previewEventData;
}

export function mapAddELearningToDto(data: ELearningFormData): ELearningData {
  const addELearningDataDto: ELearningData = {
    ...mapAddEventToDto(data),
    begin:
      data.trainingType === TrainingType.ELearning
        ? null
        : mapDateAndTime(data.beginDate, data.beginTime),
    end:
      data.trainingType === TrainingType.ELearning
        ? null
        : mapDateAndTime(data.endDate, data.endTime),
    number_of_units: data.numberOfUnits || 0,
    duration_per_unit_in_minutes: data.durationPerUnitInMinutes || 0,
    max_time_period_to_finish_elearning_module_in_weeks:
      data.maxTimePeriodToFinishElearningModuleInWeeks || 0,
    pinboard_is_activated: data.pinboardIsActivated || false,
    publish_elearning_after_event: data.publishELearningAfterEvent || false,
    evaluation_link_blended_learning: data.evaluationLinkBlendedLearning,
    participant_authentication: data.participantAuthentication ?? true,
    elearning_link: "",
  };
  return addELearningDataDto;
}

export function mapEditELearningDtoToEventForm(data: ELearningDto): ELearningFormData {
  const addELearningDataDto: ELearningFormData = {
    ...mapEditEventDtoToEventForm(data),
    beginDate: data.training_type === TrainingType.ELearning ? null : moment(data.begin),
    endDate: data.training_type === TrainingType.ELearning ? null : moment(data.end),
    beginTime: data.training_type === TrainingType.ELearning ? null : moment(data.begin),
    endTime: data.training_type === TrainingType.ELearning ? null : moment(data.end),
    numberOfUnits: data.number_of_units,
    durationPerUnitInMinutes: data.duration_per_unit_in_minutes,
    maxTimePeriodToFinishElearningModuleInWeeks:
      data.max_time_period_to_finish_elearning_module_in_weeks,
    pinboardIsActivated: data.pinboard_is_activated,
    publishELearningAfterEvent: data.publish_elearning_after_event,
    evaluationLinkBlendedLearning: data.evaluation_link_blended_learning,
    participantAuthentication: data.participant_authentication ?? true,
    certificate_type: data.certificate_type ?? null,
  };
  return addELearningDataDto;
}
