import {
  Box,
  Button,
  createStyles,
  Grid,
  makeStyles,
  Popover,
  Theme,
  Typography,
  Hidden,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  LumosEducationSvg,
  LumosMapsSvg,
  LumosPointsSvg,
  LumosPriceSvg,
} from "../../../../../assets/icons/custom-svg-components/LumosSvgCollection";
import { EventListData } from "../../../../../models/eventData";
import { AppState } from "../../../../../redux";
import EventItemEndDateTime from "./date-time/EventItemEndDateTime";
import EventItemStartDateTime from "./date-time/EventItemStartDateTime";
import { EventItemBooking } from "./EventItemBooking";
import { renderStatusColor } from "./EventItemService";
import { EventItemStatusDropdown } from "./EventItemStatusDropdown";
import EventItemStatusText from "./EventItemStatusText";
import { mapStatusIdToStatusString } from "../../../../../models/enums/eventStatus.enum";
import { Utils } from "../../../../../services/utils";
import { UserType } from "../../../../../models/enums/userType.enum";
import usePermission from "../../../../../services/usePermissions";
import {
  mapToTrainingTypeString,
  TrainingType,
} from "../../../../../models/enums/trainingType.enum";
import { EventType } from "../../../../../models/enums/eventType.enum";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(1),
    },
  })
);

interface EventItemPropertiesProps {
  event: EventListData;
  preview?: boolean;
}

export const EventItemProperties: React.FC<EventItemPropertiesProps> = (props) => {
  const { t } = useTranslation(["events"]);
  const { event, preview } = props;
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const accessibility = useSelector((state: AppState) => state.accessibility);

  const { isStaff } = usePermission(currentUser?.user_type);

  const classes = useStyles();
  const [anchorElStatus, setAnchorElStatus] = React.useState<HTMLElement | null>(null);
  const openStatus = Boolean(anchorElStatus);

  const [eventWorkload, setEventWorkload] = React.useState(0);

  const handlePopoverOpenStatus = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorElStatus(e.currentTarget);
  };

  const handlePopoverCloseStatus = () => {
    setAnchorElStatus(null);
  };

  useEffect(() => {
    const percentage =
      event.training_type === TrainingType.ELearning
        ? 0
        : (event.seats_taken * 100) / event.seats;
    setEventWorkload(percentage);
  }, [event]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={4} md={12}>
          <Box component="span" display="flex" alignItems="center">
            <Hidden only={["xs", "md", "lg", "xl"]}>
              <Box flexGrow={1} />
            </Hidden>
            <LumosEducationSvg />
            {event.training_type === TrainingType.DefaultEvent
              ? `${
                  event.event_type === EventType.OnSite
                    ? t("eventLocation.onSiteEvent", { ns: "events" })
                    : t("eventLocation.onlineEvent", { ns: "events" })
                }`
              : mapToTrainingTypeString(event.training_type)}
          </Box>
        </Grid>
        {event.training_type !== TrainingType.ELearning && event.begin && event.end ? (
          <>
            <Grid item xs={12} sm={6} md={12}>
              <Box component="span" display="flex" alignItems="center">
                <EventItemStartDateTime
                  startDate={new Date(event.begin)}
                  endDate={new Date(event.end)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              <Box component="span" display="flex" alignItems="center">
                <Hidden only={["xs", "md", "lg", "xl"]}>
                  <Box flexGrow={1} />
                </Hidden>
                <EventItemEndDateTime
                  startDate={new Date(event.begin)}
                  endDate={new Date(event.end)}
                />
              </Box>
            </Grid>
          </>
        ) : null}
        {event.event_type === EventType.OnSite && (
          <Grid item xs={12} sm={4} md={12}>
            <LumosMapsSvg />
            {event.location}
          </Grid>
        )}
        <Grid item xs={12} sm={4} md={12}>
          <Box component="span" display="flex" alignItems="center">
            <Hidden only={["xs", "md", "lg", "xl"]}>
              <Box flexGrow={1} />
            </Hidden>
            <LumosPriceSvg />
            {Utils.formatPrice(event.total_amount)} {t("incl", { ns: "events" })}{" "}
            {Utils.formatTax(event.value_added_tax_rate)} % {t("tax", { ns: "events" })}
            <Hidden only={["xs", "md", "lg", "xl"]}>
              <Box flexGrow={1} />
            </Hidden>
          </Box>
        </Grid>
        {event.score && event.score > 0 ? (
          <Grid item xs={12} sm={4} md={12}>
            <Box component="span" display="flex" alignItems="center">
              <Hidden only={["xs", "md", "lg", "xl"]}>
                <Box flexGrow={1} />
              </Hidden>
              <LumosPointsSvg />
              {event.score} {t("points", { ns: "events" })}
            </Box>
          </Grid>
        ) : null}
      </Grid>

      <Hidden smDown>
        <br />
        <br />
      </Hidden>
      {!preview && (
        <Grid container style={{ bottom: "0rem" }}>
          <Grid item xs={3} />
          <Grid item xs={9}>
            <Box component="div" className="event-item-status-text">
              {event.training_type !== TrainingType.ELearning && (
                <EventItemStatusText
                  status={event.status}
                  slots={event.seats}
                  slots_taken={event.seats_taken}
                  eventWorkload={eventWorkload}
                  waiting_list={event.waiting_list}
                />
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={2}
            sm={6}
            md={3}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Box
              component="div"
              className={renderStatusColor(
                eventWorkload,
                event.status,
                accessibility,
                event.seats_taken >= event.seats,
                event.training_type === TrainingType.ELearning,
                event.is_capped
              )}
              aria-owns={openStatus ? "status-dot-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpenStatus}
              onMouseLeave={handlePopoverCloseStatus}
              style={{ marginLeft: "0rem" }}
            />
            <Popover
              id="status-dot-popover"
              className={classes.popover}
              classes={{
                paper: classes.paper,
              }}
              open={openStatus}
              anchorEl={anchorElStatus}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverCloseStatus}
              disableRestoreFocus
            >
              <Typography>
                <Box component="span" display="flex" alignItems="center">
                  <Box
                    component="span"
                    className={
                      accessibility.monoMode
                        ? "event-item-status-dot event-item-dot-mono-published"
                        : "event-item-status-dot event-item-dot-green"
                    }
                  />{" "}
                  Es sind ausreichend Plätze frei
                </Box>
                <br />
                <Box component="span" display="flex" alignItems="center">
                  <Box
                    component="span"
                    className={
                      accessibility.monoMode
                        ? "event-item-status-dot event-item-dot-mono-almost-full"
                        : "event-item-status-dot event-item-dot-yellow"
                    }
                  />{" "}
                  Nur noch wenige Plätze verfügbar
                </Box>
                <br />
                <Box component="span" display="flex" alignItems="center">
                  <Box
                    component="span"
                    className={
                      accessibility.monoMode
                        ? "event-item-status-dot event-item-dot-mono-full"
                        : "event-item-status-dot event-item-dot-red"
                    }
                  />{" "}
                  Keine Plätze mehr verfügbar
                </Box>
              </Typography>
            </Popover>
          </Grid>
          <Grid item xs={10} sm={6} md={9}>
            {isStaff() ? (
              <EventItemStatusDropdown event={event} />
            ) : currentUser?.user_type === UserType.Speaker ? (
              <Button
                disabled
                id="event-item-status"
                size="medium"
                variant="contained"
                fullWidth
                style={{ marginLeft: "auto" }}
              >
                {mapStatusIdToStatusString(event.status)}
              </Button>
            ) : (
              <EventItemBooking eventWorkload={eventWorkload} eventData={event} />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};
