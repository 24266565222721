import * as React from "react";
import { Button, ButtonProps, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { AppState } from "../../redux";

export interface CustomButtonProps {
  customColor?: string;
  textColor?: string;
  hoverColor?: string;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  icon?: React.ReactNode;
}

interface MonoProps {
  monoMode: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: (props: CustomButtonProps & MonoProps) =>
      props.monoMode ? "#000" : props.customColor,
    color: (props: CustomButtonProps & MonoProps) =>
      props.monoMode ? "#fff" : props.textColor ? props.textColor : "#fff",
    "&:hover": {
      background: (props: CustomButtonProps & MonoProps) =>
        props.monoMode ? "#fff" : props.hoverColor,
      color: (props: CustomButtonProps & MonoProps) =>
        props.monoMode ? "#000" : "#fff",
      [theme.breakpoints.down("sm")]: {
        padding: "8px 16px",
        fontSize: "15px",
      },
    },
  },
}));

const CustomButton: React.FC<CustomButtonProps & ButtonProps> = (props) => {
  const accessibility = useSelector((state: AppState) => state.accessibility);
  const classes = useStyles({ ...props, monoMode: accessibility.monoMode });
  const { className, icon, children, ...other } = props;
  return (
    <Button
      variant="contained"
      className={[classes.root, className].join(" ")}
      startIcon={icon}
      {...other}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
