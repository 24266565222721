/* eslint-disable camelcase */
import { Grid } from "@material-ui/core";
import { useFormikContext } from "formik";
import * as React from "react";
import { useDispatch } from "react-redux";
import { BookingFilterData } from "../../../../models/bookingData";
import { getParticipationStatusForSelect } from "../../../../models/enums/participationStatus.enum";
import { BookingsSortBy } from "../../../../models/enums/sortBy.enum";
import {
  mapToTrainingTypeString,
  TrainingType,
} from "../../../../models/enums/trainingType.enum";
import {
  getAllMyEvents,
  getAllMyEventsForPrint,
} from "../../../../redux/bookings/actions";
import { BookingFilterService } from "../../../../services/filters/bookingFilterService";
import CustomMultiSelect from "../../../forms/selects/CustomMultiSelect";
import CustomSelect from "../../../forms/selects/CustomSelect";
import { SelectData } from "../../../forms/selects/selectData";
import { useTranslation } from "react-i18next";

interface BookingFilterFormProps {
  page: number;
}

const BookingFilterForm: React.FC<BookingFilterFormProps> = (props) => {
  const { t } = useTranslation(["enums", "events", "snackbars"]);
  const dispatch = useDispatch();
  const { setFieldValue, values } = useFormikContext<BookingFilterData>();
  const { page } = props;

  function getSortOptions() {
    let options = (Object.values(BookingsSortBy) as string[]).map((sortByEnumValue) => {
      return {
        key: sortByEnumValue,
        value: sortByEnumValue,
      };
    });

    options = options.filter((x) => x.value !== BookingsSortBy.EndAscending);

    return options;
  }

  function getTypeOptions() {
    const filterOptions: SelectData[] = [];
    filterOptions.push({
      key: TrainingType.DefaultEvent.toString(),
      value: TrainingType.DefaultEvent,
      label: mapToTrainingTypeString(TrainingType.DefaultEvent),
    });
    filterOptions.push({
      key: TrainingType.ELearning.toString(),
      value: TrainingType.ELearning,
      label: mapToTrainingTypeString(TrainingType.ELearning),
    });
    filterOptions.push({
      key: TrainingType.BlendedLearning.toString(),
      value: TrainingType.BlendedLearning,
      label: mapToTrainingTypeString(TrainingType.BlendedLearning),
    });
    return filterOptions;
  }

  const sortOptions = React.useMemo(() => {
    return getSortOptions();
  }, [t]);

  const typeOptions = React.useMemo(() => {
    return getTypeOptions();
  }, [t]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={8} sm={4}>
        <CustomSelect
          name="event__training_type"
          id="type-select"
          label={t("eventType.title", { ns: "events" })}
          labelId="type-select-label"
          defaultText="all"
          onChange={(
            event: React.ChangeEvent<{
              name?: string;
              value: unknown;
            }>
          ) => {
            const value: string = event.target.value as string;
            setFieldValue("event__training_type", value);

            dispatch(
              getAllMyEvents(page, {
                ...values,
                event__training_type: value,
              })
            );
            dispatch(
              getAllMyEventsForPrint({
                ...values,
                event__training_type: value,
              })
            );
          }}
          options={typeOptions}
        />
      </Grid>
      <Grid item xs={8} sm={4}>
        <CustomMultiSelect
          name="status"
          id="status-multiselect"
          label={t("status.titleAlt", { ns: "events" })}
          labelId="status-multiselect-label"
          defaultOptions={values.status || []}
          hasCustomChangeHandler
          onBlur={() => {
            const bookingFilterService = new BookingFilterService(page, values);

            const statusHasChanged = bookingFilterService.checkIfFilterHasChanged();

            if (statusHasChanged) {
              dispatch(
                getAllMyEvents(page, {
                  ...values,
                  status: values.status,
                })
              );
              dispatch(
                getAllMyEventsForPrint({
                  ...values,
                  status: values.status,
                })
              );
            }
          }}
          onClose={() => {
            // !workaround to make the onBlur method work
            setTimeout(() => {
              const statusMultiSelect = document.getElementById("status-multiselect");
              if (statusMultiSelect) statusMultiSelect.blur();
            }, 0);
          }}
          customChangeHandler={(changedMultiSelectValues) => {
            setFieldValue("status", changedMultiSelectValues);
          }}
          options={getParticipationStatusForSelect()}
        />
      </Grid>
      <Grid item xs={8} sm={4}>
        <CustomSelect
          name="sorting"
          id="sorting-select"
          label={t("sorting", { ns: "events" })}
          defaultValueForEmptyElement={BookingsSortBy.EndAscending}
          defaultText={BookingsSortBy.EndAscending}
          onChange={(
            event: React.ChangeEvent<{
              name?: string;
              value: unknown;
            }>
          ) => {
            // overwrite the change method
            setFieldValue("sorting", event.target.value);

            dispatch(
              getAllMyEvents(page, {
                ...values,
                sorting: event.target.value as BookingsSortBy,
              })
            );
            dispatch(
              getAllMyEventsForPrint({
                ...values,
                sorting: event.target.value as BookingsSortBy,
              })
            );
          }}
          labelId="sorting-select-label"
          options={sortOptions}
        />
      </Grid>
    </Grid>
  );
};

export default BookingFilterForm;
