import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../redux";
import { Box, Grid } from "@material-ui/core";
import { removePassImageCapture } from "../../../../../redux/videoAuthentication/actions";
import { ImageWithDeleteIcon } from "../misc/ImageWithDeleteIcon";
import { useTranslation } from "react-i18next";

/**
 * Renders a list of ID card captures.
 *
 * @component
 * @returns {JSX.Element} - Rendered component.
 */
export function CaptureIdCardList() {
  const { t } = useTranslation(["authCapture", "snackbars"]);

  const { documentCaptures: passCaptures } = useSelector(
    (state: AppState) => state.videoAuthentication
  );
  const dispatch = useDispatch();

  /**
   * Handles the removal of an ID card capture.
   *
   * @param {string} capture - The ID card capture to be removed.
   */
  function handleRemoveCapture(capture: string) {
    dispatch(removePassImageCapture(capture));
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box>{t("authDocs.title", { ns: "authCapture" })}</Box>
      </Grid>
      {passCaptures.length > 1 && (
        <Grid item xs={12}>
          <Box>{t("shots.shotSelect", { ns: "authCapture" })}</Box>
        </Grid>
      )}
      {passCaptures.map((capture, index) => {
        return (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={capture}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div style={{ maxWidth: "100%", padding: ".625rem" }}>
              <ImageWithDeleteIcon
                src={capture}
                alt={`${t("shots.shot", { ns: "authCapture" })} ${index}`}
                title={t("shots.title", { ns: "authCapture" })}
                onDelete={() => handleRemoveCapture(capture)}
                isMemberShipDocumentPage={false}
              />
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
}
