import { Grid, Button } from "@material-ui/core";
import { ProceedButton } from "./ProceedButton";
import { useHistory } from "react-router-dom";
import routes from "../../../../../routing/routes";
import { useTranslation } from "react-i18next";

export function NavControls(props: { canProceed: boolean; onProceed: () => void }) {
  const history = useHistory();
  const { t } = useTranslation(["common", "snackbars"]);
  const { canProceed, onProceed } = props;

  return (
    <Grid container justifyContent="space-between" style={{ padding: 5 }}>
      <Grid item>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => history.push(routes.elearning_dashboard)}
        >
          {t("forms.buttons.cancel", { ns: "common" })}
        </Button>
      </Grid>
      <Grid item>
        <ProceedButton onClick={onProceed} color="primary" disabled={!canProceed} />
      </Grid>
    </Grid>
  );
}
