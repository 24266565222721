import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../redux";
import { Redirect } from "react-router-dom";
import { UserType } from "../models/enums/userType.enum";

interface AcceptTermsProtectionProps {
  children: ReactNode;
}

const AcceptTermsProtection: React.FC<AcceptTermsProtectionProps> = ({ children }) => {
  const user = useSelector((state: AppState) => state.user);

  return user.currentUser &&
    (user.currentUser?.terms_agreed_at === "" ||
      user.currentUser?.terms_agreed_at === null) &&
    user.currentUser?.user_type !== UserType.Admin ? (
    <Redirect to="/veranstaltungen" />
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};

export default AcceptTermsProtection;
