import moment from "moment";

/**
 * initialData: object - The initial data for the registration form.
 */
export const initialData = {
  first_name: "",
  last_name: "",
  guardian_email: "",
  email: "",
  birthday: "",
  group_keys: "",
  street: "",
  street_number: "",
  zip_code: "",
  city: "",
  password: "",
  salutation: "",
  confirmPassword: "",
  terms_agreed_at: null,
};

/**
 * Checks if the user needs a guardian based on the minimum age.
 * @param birthday: string - The birthday of the user.
 * @returns
 */
export const userNeedsAGuardian = (
  birthday: string,
  minimumAge: number,
  orgaSkipGuardians: boolean
) => {
  const dateFormat = "YYYY-MM-DD";
  const today = moment(new Date()).format(dateFormat);
  const todayMinusMinimumAge = moment(today)
    .subtract(minimumAge, "y")
    .format(dateFormat);
  const birthdayFormatted = moment(birthday).format(dateFormat);

  if (orgaSkipGuardians || birthdayFormatted <= todayMinusMinimumAge) {
    return false;
  }
  return true;
};
