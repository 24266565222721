import * as React from "react";
import "./Cart.scss";
import { Box, Hidden, TableCell, TableRow, Tooltip } from "@material-ui/core";
import { CartItemData } from "../../../models/cartData";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import { removeEventFromCart } from "../../../redux/cart/actions";
import { Utils } from "../../../services/utils";
import { AppState } from "../../../redux";
import { useTranslation } from "react-i18next";

interface CartItemProps {
  event: CartItemData;
  checkout?: boolean;
  hasSomeDiscount?: boolean;
}

const CartItem: React.FC<CartItemProps> = ({ event, checkout, hasSomeDiscount }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["booking", "common", "snackbars"]);
  const taxRate = Utils.formatTax(event.value_added_tax_rate);
  const userData = useSelector((state: AppState) => state.user.currentUser);
  const shortTitle =
    event.title.substring(0, 25).split(" ").slice(0, -1).join(" ") + "...";

  const localStartDate = event.begin
    ? new Date(event.begin).toLocaleDateString()
    : event.begin;
  const localBeginOrCreateDate = new Date(
    event.begin || event.created_on
  ).toLocaleDateString();
  const nettoPrice = Utils.formatPrice(event.price);

  const discountAmount = event.discount;
  const discountAmountFormated = Utils.formatDiscountAmount(discountAmount);

  const taxAmount = Utils.formatPrice(event.vat_amount, true, false);

  const bruttoPrice = event.gross_price;

  const bruttoPriceFormated = Utils.formatPrice(bruttoPrice);

  const bookedForUserName = `${event.book_for.first_name} ${event.book_for.last_name}`;

  const bookedFor = (
    <>
      {t("cart.bookedFor", { ns: "booking" })}:{" "}
      {event.book_for.id === userData?.id ? <b>{bookedForUserName}</b> : <b />}
    </>
  );

  const speaker = (
    <>
      <br />
      {event.speakers.length === 1 && "Referent:"}
      {event.speakers.length > 1 && "Referenten:"}
      <br />
      {event.speakers.map((speakerItem, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            {speakerItem}
            <br />
          </React.Fragment>
        );
      })}
    </>
  );

  /*Columns
   * smDown: EventID |
   * mdUP: EventID + shortTitle |
   * */

  return (
    <TableRow>
      <Hidden smDown>
        <TableCell component="th" scope="row">
          {event.public_id}
        </TableCell>
      </Hidden>
      <TableCell align="left">
        <Hidden mdUp>
          #{event.public_id}
          <br />
        </Hidden>
        {event.title.length > 30 ? (
          <Tooltip title={event.title} arrow>
            <Box component="span">{shortTitle}</Box>
          </Tooltip>
        ) : (
          event.title
        )}
        <Hidden smUp>
          {localStartDate ? (
            <>
              <br />
              {localStartDate}
            </>
          ) : null}
        </Hidden>
        <Hidden mdUp>
          <br />
          {bookedFor}
        </Hidden>
        <Hidden mdUp>{speaker}</Hidden>
      </TableCell>
      <Hidden xsDown>
        <TableCell align="left">{localBeginOrCreateDate}</TableCell>
      </Hidden>
      <Hidden smDown>
        <TableCell align="left">{nettoPrice}</TableCell>
      </Hidden>
      {hasSomeDiscount ? (
        <TableCell id="table-cell-card-item-discount" align="right">
          {discountAmountFormated}
        </TableCell>
      ) : null}
      {checkout ? (
        <>
          <TableCell align="right" style={{ paddingLeft: "0.625rem" }}>
            {taxRate}
          </TableCell>
          <TableCell align="right" style={{ paddingLeft: "0.625rem" }}>
            {taxAmount}
          </TableCell>
        </>
      ) : null}
      <TableCell align="right">{bruttoPriceFormated}</TableCell>
      <TableCell align="center">
        <DeleteIcon
          style={{ cursor: "pointer" }}
          onClick={() => dispatch(removeEventFromCart(event.id))}
        />
      </TableCell>
    </TableRow>
  );
};

export default CartItem;
