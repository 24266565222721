/* eslint-disable react/jsx-max-depth */
/* eslint-disable camelcase */
import { Button, FormControl, Grid, Typography, Box } from "@material-ui/core";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { validate as isValidUUID } from "uuid";
import * as yup from "yup";
import { usersService } from "../../../api";
import { useDynamicYupValidations } from "../../../libs/yup-validations";
import { ResetPasswordRequestData } from "../../../models/userData";
import routes from "../../../routing/routes";
import "./ResetPasswordPage.scss";
import { useTranslation } from "react-i18next";
import HeadingLumos from "../../../components/theming/HeadingLumos";
import i18n from "../../../localization/i18n";
import CustomTextField from "../../../components/forms/CustomTextField";

const initialData = {
  password: "",
  confirmPassword: "",
};

export const ResetPasswordPage: React.FC = () => {
  const { t } = useTranslation(["authentication", "snackbars"]);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const token = useTokenFromUrl();

  const { YupValidationConfirmPassword } = useDynamicYupValidations();

  const validationSchema = yup.object({
    password: YupValidationConfirmPassword("confirmPassword"),
    confirmPassword: YupValidationConfirmPassword("password"),
  });

  return (
    <Box>
      <Grid item xs={12} md={9}>
        <HeadingLumos>
          {t("password.forgotten.passwordNewHeader", {
            ns: "authentication",
          })}
        </HeadingLumos>

        <Typography style={{ marginBottom: "1.25rem" }}>
          {t("password.forgotten.noteResetRequest", {
            ns: "authentication",
          })}
        </Typography>

        <Formik
          key={i18n.language}
          initialValues={initialData}
          onSubmit={async (values) => {
            const data: ResetPasswordRequestData = {
              reset_password_token: token,
              password: values.password,
              confirm_password: values.confirmPassword,
            };
            usersService.resetPassword(data).then((success) => {
              if (success) {
                enqueueSnackbar(
                  t("profile.passwordChanged", {
                    ns: "snackbars",
                  }),
                  {
                    variant: "success",
                  }
                );
                history.push(routes.login);
              } else {
                enqueueSnackbar(
                  t("profile.passwordLinkInvalid", {
                    ns: "snackbars",
                  }),
                  { variant: "error" }
                );
              }
            });
          }}
          validationSchema={validationSchema}
        >
          <Form>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <CustomTextField
                    autoComplete="current-password"
                    name="password"
                    id="password"
                    type="password"
                    label={t("password.change.passwordNew", {
                      ns: "authentication",
                    })}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <CustomTextField
                    autoComplete="current-password"
                    name="confirmPassword"
                    id="confirmPassword"
                    type="password"
                    label={t("password.change.passwordNewConfirm", {
                      ns: "authentication",
                    })}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={1} style={{ marginTop: "1.25rem" }}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Button
                    id="submit-reset-password"
                    type="submit"
                    size="large"
                    variant="contained"
                    color="primary"
                    disableElevation
                    disableRipple
                  >
                    {t("login", { ns: "authentication" })}
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </Grid>
    </Box>
  );
};

const useTokenFromUrl = () => {
  const token = useLocation().pathname.split("/")[2];
  if (isValidUUID(token)) {
    return token;
  }
  return "";
};
