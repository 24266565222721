import { Typography, Link } from "@material-ui/core";
import apiRoutes from "../api/apiRoutes";
import useTimeout from "../components/hooks/UseTimeout";
import { useTranslation } from "react-i18next";

const AdminRedirect = () => {
  const { t } = useTranslation(["layout", "snackbars"]);

  const redirectToAdminPanel = () => {
    window.location.href = `${apiRoutes.admin}/`;
  };

  useTimeout(redirectToAdminPanel, 3000);

  return (
    <Typography align="center">
      {t("admin.redirectAdmin", { ns: "layout" })}
      <br />
      <br />
      {t("admin.link.part1", { ns: "layout" })}
      <i>
        <Link href={`${apiRoutes.admin}/`}>
          {" "}
          {t("admin.link.part2", { ns: "layout" })}{" "}
        </Link>
      </i>
      {t("admin.link.part3", { ns: "layout" })}.
    </Typography>
  );
};

export default AdminRedirect;
