/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Typography, Grid, Box } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { usersService } from "../../../api";
import { UserAccountActivationData } from "../../../models/userData";
import { ResendEmailLink } from "../../../components/core/registration/ResendEmailLink";
import routes from "../../../routing/routes";
import HeadingLumos from "../../../components/theming/HeadingLumos";
import useInterval from "../../../components/hooks/UseInterval";

export const AccountConfirmationPage: React.FC = () => {
  const MAX_COUNTDOWN = 3000;
  const CHECK_EACH_SECOND = 1000;

  const { t } = useTranslation(["authentication", "snackbars"]);
  const [text, setText] = useState<string>("registration.activation.redirect");
  const [linkInvalid, setLinkInvalid] = useState<boolean>(false);
  const [countdown, setCountdown] = useState(MAX_COUNTDOWN / CHECK_EACH_SECOND);
  const [countdownisActive, setCountdownisActive] = useState<boolean>(false);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  useInterval(
    () => {
      const newCount = countdown - 1;
      setCountdown(newCount);
      if (newCount === 0) {
        setCountdownisActive(false);
      }
    },
    countdownisActive ? CHECK_EACH_SECOND : null
  );
  useEffect(() => {
    (async () => {
      // TODO: Is valid token received?
      const token = new URLSearchParams(window.location.search).get("token");
      if (token !== null) {
        const data: UserAccountActivationData = { token };
        // api call
        const accountActivation = await usersService.activate(data);
        if (accountActivation) {
          setLinkInvalid(false);
          setCountdownisActive(true);

          setTimeout(() => {
            enqueueSnackbar(t("profile.accountActivated", { ns: "snackbars" }), {
              variant: "success",
            });
            history.push(routes.login);
          }, 5000);
        } else {
          setText(
            t("registration.activation.activationFail", {
              ns: "authentication",
            })
          );

          setLinkInvalid(true);
        }
      } else {
        setText(
          t("registration.activation.activationLinkInvalid", {
            ns: "authentication",
          })
        );
      }
    })();
  }, []);

  useEffect(() => {
    if (!linkInvalid && countdownisActive) {
      setText(
        t("registration.activation.activationSuccess", {
          countdown: countdown,
          ns: "authentication",
        })
      );
    }
  }, [countdown, linkInvalid, countdownisActive]);

  return (
    <Grid item xs={12} md={9} style={{ paddingBottom: ".375rem" }}>
      <HeadingLumos>
        {t("registration.activation.title", { ns: "authentication" })}
      </HeadingLumos>

      <Box style={{ width: "100%", marginBottom: "1.25rem" }}>
        <Typography>{t(text, { ns: "authentication" })}</Typography>
      </Box>
      {linkInvalid ? <ResendEmailLink setText={setText} /> : null}
    </Grid>
  );
};
