import React from "react";
import { Box, Grid, Link } from "@material-ui/core";
import {
  BFDI_RIGHT_OF_INFORMATION_URL,
  BFDI_URL,
  PRIVACY_URL,
} from "../../routing/externalUrls";

export const PrivacyPolicyPage: React.FC = () => {
  const EMAIL_INFO = "info@amanzii.com";
  const EMAIL_PRIVACY = "datenschutz@amanzii.com";

  return (
    <Grid container>
      <Grid item sm={7}>
        <h1>Datenschutzerklärung</h1>
      </Grid>
      <Grid item sm={12}>
        <Box>
          <b>Datenschutzhinweise nach Artikel 13,14 DSGVO</b>
          <p>
            {" "}
            Für die Bereitstellung des Weiterbildungs-, und Belehrungssystems LUMOS ist
            uns der verantwortungsbewusste Umgang mit personenbezogenen Daten sehr
            wichtig. Wir möchten, dass Sie wissen, warum wir welche Daten erheben und wie
            wir sie verwenden. Wir haben technische und organisatorische Maßnahmen
            getroffen, die sicherstellen, dass die Vorschriften über den Datenschutz
            sowohl von uns als auch von unseren externen Dienstleistern beachtet werden.
            Maßgebliche Gesetze sind dabei die Datenschutz-Grundverordnung (DSGVO), das
            Bundesdatenschutzgesetz (BDSG) und das Digitale Dienste Gesetz (DDG).
          </p>
          <p>
            Grundsätzlich ist die Nutzung der Internetseite ohne Angabe personenbezogener
            Daten möglich. Sofern Sie die Leistung des Weiterbildungs- und
            Belehrungssystems LUMOS in Anspruch nehmen möchten, ist eine Verarbeitung
            personenbezogener Daten erforderlich.
          </p>
          <p>
            Wir behalten uns vor, diese Datenschutzerklärung gelegentlich anzupassen,
            damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um
            Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen. Für
            Ihren Besuch gilt immer die aktuelle Datenschutzerklärung. Weitere
            Informationen zum Datenschutz und zum Datenschutzbeauftragten der Firma
            amanzii finden Sie in der{" "}
            <Link href={PRIVACY_URL} target="_blank">
              Datenschutzerklärung
            </Link>{" "}
            auf unserer Website.
          </p>
          <b>1. Name und Kontaktdaten der verantwortlichen Stelle i.S.d. DSGVO</b>
          <p>
            Verantwortlicher: <br />
          </p>
          amanzii GmbH <br />
          Frankfurter Straße <br />
          1A 61231 Bad Nauheim <br />
          <p>
            <Link href={`mailto:${EMAIL_INFO}`}>{EMAIL_INFO}</Link> <br />
            vertreten durch den Geschäftsführer Oliver Kuklok.
          </p>
          <p>
            Der betriebliche Datenschutzbeauftragte ist unter der o.g. Anschrift zu
            Händen Herrn Borgis zu erreichen bzw. unter{" "}
            <Link href={`mailto:${EMAIL_PRIVACY}`}>{EMAIL_PRIVACY}</Link> <br />
            per E-Mail.
          </p>
          <b>
            2. Kategorien von personenbezogenen Daten, deren Erhebung, Speicherung so wie
            Art und Zweck der Verarbeitung
          </b>
          <p>
            Sie können sich auf unserer Website registrieren, um zusätzliche Funktionen
            auf der Seite zu nutzen. Die dazu eingegebenen Daten verwenden wir nur zum
            Zwecke der Nutzung des jeweiligen Angebotes oder Dienstes, für den Sie sich
            registriert haben. Die bei der Registrierung abgefragten Pflichtangaben
            müssen vollständig angegeben werden. Anderenfalls werden wir die
            Registrierung ablehnen.
          </p>
          <b>Namen und Adressen</b>
          <p>
            Wenn Sie eine Onlinebelehrung durchführen, speichern wir folgende Daten
            zweckgebunden ausschließlich in Deutschland im Auftrag:
          </p>
          <ul style={{ paddingLeft: "2rem" }}>
            <li>E-Mail als Benutzername und zur Kommunikation</li>
            <li>
              Anrede, Vorname, Name, Adresse zur Erstellung der Zertifikate und Ansprache
            </li>
            <li>
              Geburtsdatum, um bei Minderjährigen die Einverständniserklärung einholen zu
              können. In diesem Fall speichern wir auch das Einverständnis des
              Sorgeberechtigten inkl. der Kontakt E-Mail
            </li>
            <li>
              Erreichte Zertifikate aus der Teilnahme an Belehrungen und Schulungen{" "}
            </li>
          </ul>
          <p>
            Die Daten werden gespeichert, solange der Nutzer das Konto aufrechterhält.
            Wenn das Konto durch den Nutzer zur Löschung gekennzeichnet wird, kann von
            außen nicht mehr auf die Daten zugegriffen werden. Nach Entfall des Zwecks
            oder dem Ablauf der Dokumentationspflicht von 2,5 Jahren nach EU-DSGVO Art. 6
            Abs.1a), b), c), e) des Auftraggebers werden diese Daten gelöscht. In dieser
            Zeit hat der Nutzer die Möglichkeit sein Konto zu reaktivieren.
          </p>
          <b>Biometrische Daten</b>
          <p>
            Für die Authentisierung zum Start der Belehrung werden Fotos durch die Kamera
            des Endgerätes des Nutzers erstellt. Diese biometrischen Daten werden nach
            Art. 6 Abs.1 a), b), e) für drei Monate gespeichert, um den Nutzer bei Fragen
            zur Vertragserfüllung unterstützen zu können. Anschließend werden jegliche
            biometrischen Daten ohne weitere Verarbeitung automatisch gelöscht.
          </p>
          <b>Bezahlsysteme</b>
          <p>
            Die notwendigen Daten nach Art. 6 Abs.1 zur Nutzung eines
            Sofort-Bezahlungssystems als Online-Bankkunde oder des vom Nutzer gewählten
            Zahlungsdienstleisters dürfen erhoben und verarbeitet werden.
          </p>
          <p>
            Auf unserer Website bieten wir die Bezahlung über den Zahlungsdienstleister
            PayPal (Europe) S.à.r.l. et Cie, S.C.A., 22-24 Boulevard Royal, L-2449
            Luxembourg (im Folgenden “PayPal”) an. Wenn Sie die Bezahlung via PayPal
            auswählen, werden die von Ihnen eingegebenen Zahlungsdaten an PayPal
            übermittelt.
          </p>
          <p>
            Die Übermittlung Ihrer Daten an PayPal erfolgt auf Grundlage von Art. 6 Abs.
            1 lit. a DSGVO (Einwilligung) und Art. 6 Abs. 1 lit. b DSGVO (Verarbeitung
            zur Erfüllung eines Vertrags). Sie haben die Möglichkeit, Ihre Einwilligung
            zur Datenverarbeitung jederzeit zu widerrufen. Ein Widerruf wirkt sich auf
            die Wirksamkeit von in der Vergangenheit liegenden
            Datenverarbeitungsvorgängen nicht aus.
          </p>
          <p>
            Dem Nutzer ist bewusst, dass amanzii keinen Einfluss darauf hat, was mit
            personenbezogenen Daten im Zusammenhang mit der Nutzung von
            Kommunikationstools außerhalb des Einflussbereiches von amanzii geschieht.
          </p>
          <b>Kommunikation über E-Mail</b>
          <p>
            Dem Nutzer ist bewusst, dass der Versand von E-Mails mit einem Risiko
            verbunden sein könnte. Der Nutzer bestätigt, dass er sich ausreichend über
            diese eventuellen Risiken informiert hat, und erklärt das Einverständnis.
          </p>
          <b>3. Übermittlung von Daten an Dritte</b>
          <p>
            Eine Übermittlung von oben genannten Daten an Dritte, außerhalb des
            Auftragsverarbeitungsvertrags nach EU-DSGVO erfolgt ausdrücklich nicht!
          </p>
          <b>4. Ihre Betroffenenrechte</b>
          <p>
            Als Betroffener gem. DSGVO haben Sie Rechte, die Sie wahrnehmen können.
            Weitere Informationen hierzu erhalten Sie in dem{" "}
            <Link href={BFDI_RIGHT_OF_INFORMATION_URL} target="_blank">
              Artikel zum Recht auf Auskunft (Art. 15 DSGVO)
            </Link>{" "}
            auf der Website der Bundesbeauftragten für den Datenschutz und die
            Informationsfreiheit (BfDI).
          </p>
          <b>5. Widerspruch gegen die Verarbeitung</b>
          <p>
            Insofern Ihre personenbezogenen Daten auf Grund von berechtigten Interessen
            des Verantwortlichen erhoben wurden (Art. 6 Abs. 1 f) DSGVO), haben Sie das
            Recht Widerspruch gegen die Verarbeitung einzulegen. Um von ihrem Recht
            Gebrauch zu machen, genügt eine E-Mail an{" "}
            <Link href={`mailto:${EMAIL_PRIVACY}`}>{EMAIL_PRIVACY}</Link>. Ein Widerruf
            der Einwilligung hat die sofortige Beendigung des Nutzungsvorgangs und die
            Löschung aller Daten zur Folge.
          </p>
          <p>
            Die Erfassung der Daten zur Bereitstellung der Website und die Speicherung
            der Daten in Logfiles ist für den Betrieb der Internetseite zwingend
            erforderlich. Es besteht folglich seitens des Nutzers in diesem Fall keine
            Widerspruchsmöglichkeit.
          </p>
          <p>
            Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen
            Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei der zuständigen
            Landesdatenschutzaufsichtsbehörde zu, wenn Sie der Ansicht sind, dass die
            Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die
            Datenschutz-Grundverordnung verstößt. Welche
            Landesdatenschutzaufsichtsbehörde für uns zuständig ist, können Sie unter
            <Link href={BFDI_URL} target="_blank">
              {" "}
              Anschriften und Links{" "}
            </Link>{" "}
            auf der Website der BfDI ansehen.
          </p>
          <p>
            Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet
            den Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde
            einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs nach Art. 78
            DSGVO.
          </p>
        </Box>
      </Grid>
    </Grid>
  );
};
