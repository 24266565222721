/* eslint-disable react/jsx-max-depth */
import { Button, FormControl, Grid, Hidden, Typography, Box } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { usersService } from "../../../api";
import { useDynamicYupValidations } from "../../../libs/yup-validations";
import { ForgotPasswordRequestData } from "../../../models/userData";
import CustomTextField from "../../../components/forms/CustomTextField";
import routes from "../../../routing/routes";
import "./ForgotPasswordPage.scss";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import HeadingLumos from "../../../components/theming/HeadingLumos";

const initialData = {
  email: "",
  confirmEmail: "",
};

export const ForgotPasswordPage: React.FC = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["authentication", "snackbars"]);

  const { YupValidationConfirmEmail } = useDynamicYupValidations();

  const validationSchema = yup.object({
    email: YupValidationConfirmEmail("confirmEmail"),
    confirmEmail: YupValidationConfirmEmail("email"),
  });

  return (
    <Box>
      <Grid item xs={12} md={9}>
        <HeadingLumos>
          {t("password.forgotten.title", { ns: "authentication" })}
        </HeadingLumos>

        <Typography style={{ marginBottom: "1.25rem" }}>
          {t("password.forgotten.noteChangeRequest", { ns: "authentication" })}
        </Typography>
        <Formik
          key={i18n.language}
          initialValues={initialData}
          onSubmit={async (values) => {
            const data: ForgotPasswordRequestData = {
              email: values.email,
              confirmEmail: values.confirmEmail,
            };
            usersService.forgotPassword(data).then((success) => {
              if (success) {
                enqueueSnackbar(t("profile.passwordRequest", { ns: "snackbars" }), {
                  variant: "success",
                });
                history.push(routes.login);
              }
            });
          }}
          validationSchema={validationSchema}
        >
          <Form>
            <Grid container spacing={4}>
              <Grid item lg={6} xs={12}>
                <Field
                  as={CustomTextField}
                  id="mail-enter"
                  style={{ width: "100%" }}
                  className="BaseField"
                  fullWidth
                  type="E-Mail"
                  name="email"
                  variant="filled"
                  label={t("password.forgotten.mailEnter", {
                    ns: "authentication",
                  })}
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <Field
                  as={CustomTextField}
                  style={{ width: "100%" }}
                  className="BaseField"
                  fullWidth
                  type="E-Mail"
                  name="confirmEmail"
                  id="confirm-email"
                  variant="filled"
                  label={t("password.forgotten.mailConfirm", {
                    ns: "authentication",
                  })}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Hidden mdDown>
                <Grid item lg={2} md={2} xl={3} />
              </Hidden>
              <Grid item xs={12}>
                <FormControl className="Button" fullWidth>
                  <Button
                    id="password-link"
                    type="submit"
                    size="large"
                    variant="contained"
                    color="primary"
                    disableElevation
                    disableRipple
                  >
                    {t("password.forgotten.passwordResetLink", {
                      ns: "authentication",
                    })}
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </Grid>
    </Box>
  );
};
