import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { Box, Button } from "@material-ui/core";
import { CheckCircleOutlineRounded, ErrorRounded } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../components/theming/CustomButton";
import routes from "../../../routing/routes";
import styles from "./../../../styles/custom-styles.module.scss";
import HeadingLumos from "../../../components/theming/HeadingLumos";
import Loader from "../../../components/theming/loader/Loader";

interface AfterCheckoutPageProps {
  paymentMethod: "paypal" | "bill";
}

/**
 * Page that is shown after the checkout process is completed.
 * @param paymentMethod - The payment method that was used.
 * @returns The AfterCheckoutPage component.
 */
export const AfterCheckoutPage: React.FC<AfterCheckoutPageProps> = ({
  paymentMethod,
}) => {
  const { t } = useTranslation(["booking", "common", "snackbars"]);
  const history = useHistory();
  const location = useLocation();

  const messageSuccessful =
    paymentMethod === "bill"
      ? t("afterCheckout.invoiceSuccess", { ns: "booking" })
      : t("afterCheckout.paymentSuccess", { ns: "booking" });
  const messageFailure =
    paymentMethod === "bill"
      ? t("afterCheckout.invoiceSendFail", { ns: "booking" })
      : t("afterCheckout.paymentFail", { ns: "booking" });
  const orderSuccessful = location.state;

  if (typeof orderSuccessful !== "boolean") {
    return <Loader loadingText={t("waitNote", { ns: "common" })} />;
  }
  return (
    <>
      <HeadingLumos>{t("afterCheckout.header", { ns: "booking" })}</HeadingLumos>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "1.25rem",
          gap: "3rem",
        }}
      >
        {orderSuccessful ? (
          <>
            <span>{messageSuccessful}</span>
            <CheckCircleOutlineRounded style={{ fontSize: "12rem" }} color="secondary" />
          </>
        ) : (
          <>
            <span>{messageFailure}</span>
            <ErrorRounded style={{ fontSize: "12rem", color: "#E5CCCC" }} />
          </>
        )}

        <Box style={{ display: "flex", gap: 10, flexDirection: "column" }}>
          {!orderSuccessful && (
            <CustomButton
              fullWidth
              id="back-to-payment"
              size="large"
              onClick={() => {
                history.push(routes.checkout);
              }}
              customColor={styles.red}
              textColor={styles.white}
              hoverColor={styles["red-dark"]}
            >
              {t("afterCheckout.backToPayment", { ns: "booking" })}
            </CustomButton>
          )}

          <Button
            fullWidth
            id="my-bookings-title"
            size="large"
            variant="contained"
            color="secondary"
            onClick={() => {
              history.push(routes.my_events);
            }}
          >
            {t("myBookings.title", { ns: "events" })}
          </Button>

          <Button
            fullWidth
            id="push-to-dashboard"
            size="large"
            variant="text"
            color="secondary"
            onClick={() => {
              history.push(routes.home);
            }}
          >
            {t("backToOverview", { ns: "common" })}
          </Button>
        </Box>
      </div>
    </>
  );
};
