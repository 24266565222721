export const MAIN_URL = process.env.REACT_APP_LUMOS_MAIN_URL || "";
export const EVENTS_2021 = `${MAIN_URL}/termine-fortbildung/fortbildung/`; // TODO: remove?
export const PRIVACY_URL = `https://www.amanzii.com/datenschutz/`;
export const BFDI_URL = `https://www.bfdi.bund.de/DE/Service/Anschriften/anschriften_table.html`;
export const BFDI_RIGHT_OF_INFORMATION_URL = `https://www.bfdi.bund.de/DE/Buerger/Inhalte/Allgemein/Betroffenenrechte/Betroffenenrechte_Auskunftsrecht.html`;
export const IMPRINT_URL =
  process.env.REACT_APP_IMPRESSUM_LINK || `${MAIN_URL}/impressum`;
export const TERMS_AND_CONDITIONS_PDF_URL = `${MAIN_URL}/static/build/files/amanzii-agb.pdf`;
export const TERMS_AND_CONDITIONS_URL = `${MAIN_URL}/agb`;
export const WEB_ACCESSIBILITY_URL = `${MAIN_URL}/barrierefreiheit`;
export const LOGIN_URL = `${MAIN_URL}/login/`;
export const KONTAKT_URL = `${MAIN_URL}/kontakt`;
export const MY_ACCOUNT_URL = `${MAIN_URL}/mein-konto`;
export const DOCTORS_OFFICE_LANR = `${MAIN_URL}/praxismanagement/arzt-psychotherapeutenregister/`;
export const CHANGE_ALIAS = `${MY_ACCOUNT_URL}/alias-vergeben-aendern/`;
export const REGISTER_URL = `${MY_ACCOUNT_URL}/registrieren/`;
export const FORGOT_PASSWORD_URL = `${MY_ACCOUNT_URL}/passwort-vergessen/`;
export const CHANGE_PASSWORD_URL = `${MY_ACCOUNT_URL}/passwort-aendern/`;
