import React from "react";
import HeadingLumos from "../../../components/theming/HeadingLumos";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux";
import { useEffect } from "react";
import { setPageCount } from "../../../redux/invoices/actions";
import { getInvoices } from "../../../redux/invoices/actions";
import {
  Box,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Pagination as PaginationUI } from "@material-ui/lab";
import { PAGE_SIZE } from "../../../models/pagination";
import Loader from "../../../components/theming/loader/Loader";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Utils } from "../../../services/utils";
import "./UserInvoicePage.scss";
import { useTranslation } from "react-i18next";

export const UserInvoicePage: React.FC = () => {
  const { t } = useTranslation(["common", "invoices", "snackbars"]);

  const dispatch = useDispatch();

  const accessibility = useSelector((state: AppState) => state.accessibility);
  const invoices = useSelector((state: AppState) => state.invoices);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(setPageCount(value));
  };

  const loadData = React.useCallback(() => {
    if (!invoices.isLoading && !invoices.myInvoiceListLoaded) {
      dispatch(getInvoices(invoices.currentPage));
    }
  }, [dispatch, invoices.currentPage, invoices.isLoading, invoices.myInvoiceListLoaded]);

  useEffect(loadData, [loadData]);
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <HeadingLumos>{t("header", { ns: "invoices" })}</HeadingLumos>
        </Grid>
      </Grid>
      {invoices.isLoading ? (
        <Loader />
      ) : invoices.myInvoiceList?.count === 0 ? (
        <Box
          component="span"
          display="flex"
          alignItems="center"
          style={{ height: "6.25rem" }}
        >
          {t("none", { ns: "invoices" })}
        </Box>
      ) : (
        <Grid item xs={12} style={{ marginTop: "1.25rem" }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow id="table-row">
                  <TableCell>{t("invoiceNumber", { ns: "invoices" })}</TableCell>
                  <TableCell>{t("employerProfile.company", { ns: "common" })}</TableCell>
                  <TableCell>{t("recipientLastName", { ns: "invoices" })}</TableCell>
                  <TableCell>{t("recipientFirstName", { ns: "invoices" })}</TableCell>
                  <TableCell>{t("date", { ns: "invoices" })}</TableCell>
                  <TableCell>{t("download", { ns: "common" })}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices.myInvoiceList.results?.map((invoice) => {
                  return (
                    <TableRow key={`invoice-${invoice.id}`} id="table-row">
                      <TableCell>{invoice.invoice_number}</TableCell>
                      <TableCell>
                        {invoice.order.billing_address
                          ? invoice.order.billing_address.company
                          : ""}
                      </TableCell>
                      <TableCell>
                        {invoice.order.billing_address
                          ? invoice.order.billing_address.last_name
                          : ""}
                      </TableCell>
                      <TableCell>
                        {invoice.order.billing_address
                          ? invoice.order.billing_address.first_name
                          : ""}
                      </TableCell>
                      <TableCell>
                        {Utils.formatDateToCetFormat(invoice.invoice_date)}
                      </TableCell>
                      <TableCell>
                        <Link
                          href={invoice.file_name}
                          download={invoice.file_name}
                          target="_blank"
                          className="myinvoices-document-download-icon"
                        >
                          <GetAppIcon
                            color={accessibility.monoMode ? "primary" : undefined}
                          />
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
      <Grid container style={{ marginTop: ".625rem" }}>
        <Box component="div" style={{ margin: "auto" }}>
          <PaginationUI
            count={Math.ceil(invoices.myInvoiceList.count / PAGE_SIZE)}
            page={invoices.currentPage}
            onChange={handleChange}
          />
        </Box>
      </Grid>
    </>
  );
};
