/* eslint-disable camelcase */
import { Divider, Grid, InputAdornment } from "@material-ui/core";
import { useFormikContext } from "formik";
import React, { ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { ELearningFormData } from "../../../../../models/elearning";
import { EventStatus } from "../../../../../models/enums/eventStatus.enum";
import { TrainingType } from "../../../../../models/enums/trainingType.enum";
import { AppState } from "../../../../../redux";
import CustomCheckbox from "../../../../forms/CustomCheckbox";
import CustomTextField from "../../../../forms/CustomTextField";

interface ELearningSectionProps {
  typeOfTrainingToBeCreated: TrainingType;
}

const ELearningSection: React.FC<ELearningSectionProps> = (props) => {
  const { typeOfTrainingToBeCreated } = props;
  const { values, setFieldValue } = useFormikContext<ELearningFormData>();
  const documents = useSelector((state: AppState) => state.documents);

  return (
    <>
      {typeOfTrainingToBeCreated === TrainingType.BlendedLearning && (
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <h3>E-Learning</h3>
          </Grid>
        </>
      )}
      <>
        <Grid item xs={12} sm={4}>
          <CustomTextField
            fullWidth
            required={values.status === EventStatus.Published}
            disabled={values.status === EventStatus.Completed}
            name="numberOfUnits"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">Lerneinheiten</InputAdornment>
              ),
              inputProps: { min: 0 },
            }}
            id="numberOfUnits"
            label="Anzahl der Lerneinheiten"
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CustomTextField
            fullWidth
            required={values.status === EventStatus.Published}
            disabled={values.status === EventStatus.Completed}
            InputProps={{
              endAdornment: <InputAdornment position="end">Minuten</InputAdornment>,
              inputProps: { min: 0 },
            }}
            name="durationPerUnitInMinutes"
            id="durationPerUnitInMinutes"
            label="Dauer pro Lerneinheit"
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CustomTextField
            fullWidth
            disabled={values.status === EventStatus.Completed}
            InputProps={{
              endAdornment: <InputAdornment position="end">Wochen</InputAdornment>,
              inputProps: { min: 0 },
            }}
            name="maxTimePeriodToFinishElearningModuleInWeeks"
            id="maxTimePeriodToFinishElearningModuleInWeeks"
            label="Maximaler Bearbeitungszeitraum"
            type="number"
          />
        </Grid>
        {typeOfTrainingToBeCreated === TrainingType.BlendedLearning && (
          <>
            <Grid item xs={12} sm={3}>
              <CustomCheckbox
                name="pinboardIsActivated"
                label={
                  <>
                    Pinnwand <b>aktivieren</b>
                  </>
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomCheckbox
                name="publishELearningAfterEvent"
                onChange={(event: ChangeEvent<HTMLInputElement>, checked?: boolean) => {
                  const valueBefore = values.publishELearningAfterEvent;
                  if (checked !== undefined) {
                    setFieldValue("publishELearningAfterEvent", checked);
                    if (!checked && valueBefore) {
                      // ! manually set the value for publish_at_elearning_start checkbox to false because it gets disabled
                      documents?.documentList?.forEach((document) => {
                        document.publish_at_elearning_start = false;
                        // setFieldValue(`documents[${index}].publish_after_event`, false);
                      });
                    }
                  }
                }}
                label={
                  <>
                    E-Learning <b>nach</b> Veranstaltung freischalten
                  </>
                }
              />
            </Grid>
          </>
        )}
      </>
    </>
  );
};

export default ELearningSection;

// TODO no translations yet
