import React from "react";
import { Button, Grid } from "@material-ui/core";
import "./CheckoutButtons.scss";
import { useHistory } from "react-router-dom";
import routes from "../../../../../routing/routes";
import { useTranslation } from "react-i18next";

interface CheckoutButtonsProps {
  isPaypal: boolean;
  isLoading: boolean;
}

export const CheckoutButtons: React.FC<CheckoutButtonsProps> = ({
  isPaypal,
  isLoading,
}) => {
  const { t } = useTranslation(["booking", "common", "snackbars"]);
  const history = useHistory();

  return (
    <Grid item>
      <div className="checkout-btn-wrapper">
        <Button
          id="forms-buttons-back"
          size="medium"
          variant="contained"
          color="secondary"
          onClick={() => {
            history.push(routes.checkout);
          }}
          className="checkout-btn-box"
        >
          {t("forms.buttons.back", { ns: "common" })}
        </Button>
        {!isPaypal && (
          <Button
            id="after-checkout"
            size="medium"
            variant="contained"
            color="primary"
            type="submit"
            disabled={isLoading}
            className="checkout-btn-box"
          >
            {t("afterCheckout.completeBooking", { ns: "booking" })}
          </Button>
        )}
      </div>
    </Grid>
  );
};
